$(document).ready(function(){
	stopPropagation();
	resolutionCheck();
	open();
	close();
	closeMDL();
	drop();
	closeDropdown();
	scrollToTop();
	triggerClick();
	toggleTabsActiveClass();
	toggleDisplayByTab();
	objectFitImages();
	androidDeviceDetection();
	toggle();
	scrollToElement();

	formItemSplitHovered();
	formItemFocused();
	showOnCheck();
	showOnRadioCheck();
	formItemActiveDisable();
	refreshStyler();
	formItemInputNumCheck();
	formItemInputNumDotCheck();
	formItemInputNumCommaCheck();
	formItemInputNumDotMinusCheck();
	formItemEmailCheck();
	preventKeyboardTriggeringOnMobile();
	removeErrorFromFormItem();

});

$(window).on('load', function(){

});

$(window).on('scroll', function(){
	var st = $(this).scrollTop();
	if (st > lastScrollTop){
		// downscroll
	}
	else {
		// upscroll
	}
	lastScrollTop = st;

});

$(window).on('resize orientationchange', function(){
	var res = resolutionCheck();
});
