/* $(document).ready(function(){
    $("*[type='tel']").mask("9 (999) 999-99-99", { autoclear: true, placeholder: " " });
}); */
$(document).ready(function(){
    // var element = document.getElementById('selector');
    var element = document.querySelector('*[type="tel"]');
    var maskOptions = {
        // mask: '+{7}(000)000-00-00'
        mask: '0(000)-000-00-00'
    };
    var mask = IMask(element, maskOptions);
});
