var dH = Math.max(
	document.body.scrollHeight, document.documentElement.scrollHeight,
	document.body.offsetHeight, document.documentElement.offsetHeight,
	document.body.clientHeight, document.documentElement.clientHeight
);
var currentScroll = 0;
var lastScrollTop = 0;

var toggleIndicator = true;
var slideToggleIndicator = true;
/**********common functions*****************/
function stopPropagation(){
	$('.stppg').on('click', function(event){
		event.stopPropagation();
	});
}
function resolutionCheck(){
	if ($('.i').length > 0){
		var res = parseInt($('.i').css('width'));
		return res;
	}
}
function open(){
	$(document).on('click', '*[data-open]', function(){
		var arr = $(this).attr('data-open');
		var target = arr.split(' ');
		for (var i = 0; i < target.length; i++) {
			$('#' + target[i]).addClass('active');
			if ($('#' + target[i]).find('.styler').length) {
				setTimeout(function () {
					$('.styler').trigger('refresh');
				}, 100)
			}
		}
	});
	$(document).on('click', '*[data-open*="mdl"]', function(){
		if(!$(this).hasClass('w-mdl-close')){
			if($('.touchevents').length){
				overlayStart();
			}
		}
	});
}
function close(){
	$(document).on('click', '*[data-close]', function () {
		var arr = $(this).attr('data-close');
		var target = arr.split(' ');
		for (var i = 0; i < target.length; i++) {
			$('#' + target[i]).removeClass('active');
		}
	});
}
function closeMDL(){
	$(document).on('click', '.w-mdl-close', function(){
		if($('.mdl-wrap.active').length > 1){
			if($(this).hasClass('mdl-wrap')){
				$(this).removeClass('active');
			}
			else {
				$(this).closest('.mdl-wrap').removeClass('active');
			}
		}
		else {
			if($(this).hasClass('mdl-wrap')){
				$(this).removeClass('active');
			}
			else {
				$(this).closest('.mdl-wrap').removeClass('active');
			}
			if($('.touchevents').length){
				overlayEnd();
			}
		}
	})
	$(document).on('click', '.mdl', function(event){
		event.stopPropagation();
	});
}

function drop(){
	$(document).on('click touch', '*[data-drop]', function(){
		var target = $(this).attr('data-drop');
		$('.w-dropdown:not(#'+target+')').removeClass('active');
		$('*[data-drop]').not(this).removeClass('active');
		if(!$('#'+target).hasClass('active')){
			$('#'+target).addClass('active');
			$(this).addClass('active');
		}
		else {
			$('#'+target).removeClass('active');
			$(this).removeClass('active');
		}
	});
}
function closeDropdown(){
	$(document).on('click touch', function(event){
		if($(event.target).closest('*[data-drop]').length){
			return;
		}
		$('.w-dropdown').removeClass('active');
		$('*[data-drop]').removeClass('active');
		// event.stopPropagation();
	});
}
$(document).on('click touch', '.w-dropdown', function(event){
	if($('.w-dropdown').hasClass('active')){
		event.stopPropagation();
	}
});
function tooltipToggle(){
	$(document).on('mouseenter mouseout', '*[data-hover]', function(){
		var target = $(this).attr('data-hover'),
			item = $('#'+target);
		if(!item.hasClass('active')){
			item.addClass('active');
		}
		else {
			item.removeClass('active');
		}
	});
}

$(document).on('mouseenter', '*[data-hover]', function(event){
	event.stopPropagation();
	var	element = $(this),
		target = $(this).attr('data-hover');
	definePoistion(element, target, 'tooltip');
});
$(document).on('click touch', '*[data-popover-position]', function(event){
	event.stopPropagation();
	var element = $(this),
		target = $(this).attr('data-drop');
	definePoistion(element, target, 'popover');
});

function toggle(){
	$(document).on('click', '*[data-toggle]', function(event){
		event.stopPropagation();
		var toggleIndicator = true;
		if(toggleIndicator == true){
			toggleIndicator = false;
			var target = $(this).attr('data-toggle');
			if(!$('#'+target).hasClass('active')){
				$('#'+target).addClass('active');
				$(this).addClass('active');
				setTimeout(function(){
					toggleIndicator = true;
				}, 250);
			}
			else {
				$('#'+target).removeClass('active');
				$(this).removeClass('active');
				setTimeout(function(){
					toggleIndicator = true;
				}, 250);
			}
		}
	});
}
function overlayStart(){
	var wS = window.pageYOffset;
	$('body').addClass('overlay-open');
	$('body').css('top', -wS+'px');
}
function overlayEnd(){
	var wS = Math.abs(parseInt($('body').css('top')));
	$('body').css('top', 'auto');
	$('body').removeClass('overlay-open');
	$(window).scrollTop(wS);
}
function remove(){
	$(document).on('click', '*[data-remove]', function(){
		var target = $(this).attr('data-remove');
		$('#'+target).remove();
	});
}
function toggleTabsActiveClass(){
	$('.w-tab').on('click', function(){
		if($(this).hasClass('active')){
			return;
		}
		else{
			$(this).parents('.w-tab-parent').find('.w-tab').removeClass('active');
			$(this).addClass('active');
		}
	});
}
function toggleDisplayByTab(){
	$(document).on('click touch', '*[data-tab]', function(){
		var target = $(this).attr('data-tab');
		$(this).addClass('active').siblings('.w-tab').removeClass('active');
		$('#'+target).addClass('active').siblings('.w-tab-section').removeClass('active');
		if($('#'+target).find('.styler').length){
			setTimeout(function() {
				$('.styler').trigger('refresh');
			}, 100);
		}
	});
}
function triggerClick(){
	$(document).on('click', '*[data-trigger]', function(){
		var target = $(this).attr('data-trigger');
		$('#'+target).trigger('click');
	});
}
function scrollToTop(){
	$('.w-scroll-top').click(function(){
		$('html,body').animate({scrollTop: 0}, 500);
	});
}
function androidDeviceDetection(){
	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
	if(isAndroid) {
		$('body').addClass('android');
	}
}
function scrollToElement() {
	$(document).on('click touch', '*[data-scroll]', function () {
		var target = $(this).attr('data-scroll');
		var targetOffsetTop = $('#' + target).offset().top;

		$('html, body').animate({ scrollTop: targetOffsetTop}, 350);
	});
}