var itemsToCompare = ['.w-item-to-wrap:not(.w-eq-complete)'];

function elementsPerRow(items) {
    var baseOffset = $(items)
        .eq(0)
        .position().top;
    var breakIndex = -1;
    $(items).each(function (i) {
        if ($(this).position().top > baseOffset) {
            breakIndex = i;
            return false;
        }
    });
    var numPerRow = breakIndex === -1 ? $(items).length : breakIndex;
    return numPerRow;
}

function wrapItems(eWrap, element, first, last) {
    var item = eWrap.find(element);
    item.find('.w-eq-height').css('height', 'auto');
    item.each(function () {
        var itemEq = eWrap.find(element).slice(first, last);
        itemEq.addClass('equalized');
        $('.w-eq-height-items-wrap > .equalized').wrapAll('<div></div>');
    });
}

function sortingItems() {
    var itemsWrap = $('.w-eq-height-items-wrap');
    var sliceNumb;
    var element;

    itemsWrap.each(function () {
        sliceNumb = elementsPerRow($(this).children());
        element = itemsToCompare[0];
        wrapItems($(this), element, 0, sliceNumb);
    });
}

function setEqHeightInRow(eToEq) {
    $('.w-eq-height-items-wrap > *:not(.w-item-to-wrap)').each(function () {
        var itemEq = $(this).find(eToEq);
        var maxH = itemEq.eq(0).height();
        itemEq.each(function () {
            maxH = $(this).height() > maxH ? $(this).height() : maxH;
        });
        itemEq.height(maxH);
    });
    $('.w-eq-height-items-wrap .equalized')
        .removeClass('equalized')
        .addClass('w-eq-complete')
        .unwrap();
}

function startEqHieghtScript() {
    if ($('.w-eq-height-items-wrap').length) {
        sortingItems();
        setEqHeightInRow('.w-eq-height');
    }
}


$(window).on('load', function () {
    if ($('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
});
$(window).on('resize orientationchange', function () {
    if ($('.w-eq-complete').length) {
        $('.w-eq-complete').removeClass('w-eq-complete');
    }
    if ($('.w-item-to-wrap').length) {
        setTimeout(function () {
            startEqHieghtScript();
        }, 50);
    }
});
$(window).on('scroll', function () {
    if ($('.w-item-to-wrap').length) {
        startEqHieghtScript();
    }
});
