var isMac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
$(document).ready(function () {
    if (isMac) {

    }
    /* iOS  detected */
    // var isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
    /* *** */
    /* safari only detect */
    /*     if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
    
        } */
});